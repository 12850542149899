<template>
    <div class="page-warpper">
        <header-link-modular></header-link-modular>
        <div class="contaienr">
            <div class="page-title flex align-center justify-between">
                <div>您现在的位置：
                    <router-link :to="item.path" class="page-title" v-for="(item,index) in titles" :key="index">
                        <span class="margin-right-xs">{{item.title}}</span>
                        <span class="margin-right-xs">{{index === titles.length -1 ? '' : '/'}}</span>
                    </router-link>
                </div>
                <div>欢迎<i>{{user.morgname}}</i>登录自助建站!</div>
            </div>
            <div class="content-container">
                <router-view @setIndex="getIndex(arguments)" class="content"></router-view>
            </div>
        </div>
        <footer-modular></footer-modular>
    </div>
</template>

<script>
    import HeaderLinkModular from "@/components/HeaderLinkModular.vue";
    import FooterModular from "@/components/FooterModular.vue";
    import {web_emof_home} from '@/utils/utils'

    export default {
        name: "Home",
        components: {
            FooterModular, HeaderLinkModular
        },
        data() {
            return {
                navIndex: 0,
                titles: [],
                web_emof_home: web_emof_home,
            }
        },
        computed: {
            user() {
                return this.$store.state.user
            }
        },
        methods: {
            getIndex(data) {
                this.navIndex = data[0]
                this.titles = data[1]
            },
            toPage(url) {
                this.$router.replace(url)
            }
        }

    }
</script>

<style scoped lang="scss">
    .page-warpper {
        display: flex;
        flex-direction: column;
        align-items: center;
        min-height: 100vh;
        background: #ebeff5;
        .contaienr {
            display: flex;
            flex-direction: column;
            flex: 1;
            width: 1200px;
            margin: 0 auto;
            .page-title {
                width: 100%;
                font-size: 16px;
                color: #0a2240;
                margin-top: 25px;
                i{
                    font-weight: bold;
                    margin: 0 6px;
                }
            }
            .content-container {
                display: flex;
                flex-direction: column;
                flex: 1;
                margin: 15px 0;
                background-color: #0a2240;
                border-radius: 10px;
                padding: 10px;
                .content {
                    display: flex;
                    flex-direction: column;
                    flex: 1;
                    background-color: #ffffff;
                    border-radius: 10px;
                    padding: 0 25px 25px;
                }
            }

        }
    }
</style>