<template>
    <div class="header-container">
        <div class="header">
            <img src="../assets/img/logo.png" alt="" class="logo">
            <div class="home flex align-center">
                <div class="service"><i class="el-icon-service"></i>客服电话：{{service_phone}}</div>
<!--                <el-link class="to" :href="`${web_emof_setting}tiaoz.html?code=${emofUserTokenSafer}&path=/st/setup`" :underline="false">机构设置</el-link>-->
                <!--<el-link class="to" :href="web_emof_news" :underline="false">消息中心</el-link>-->
                <div class="operate" @click="signOut">退出</div>
                <el-link class="to-emof" :href="`${web_emof_home}`" :underline="false">
                    <div class="title">访问知诚会首页</div>
                    <div class="tips">免费获取更多实用工具</div>
                </el-link>
            </div>
        </div>
    </div>
</template>

<script>
    import {service_phone,web_emof_home,web_emof_setting,web_emof_news} from "@/utils/utils";

    export default {
        components: {},
        name: "headers",
        data() {
            return {
                web_emof_home: web_emof_home,
                web_emof_setting: web_emof_setting,
                web_emof_news: web_emof_news,
                service_phone: service_phone
            }
        },
        created() {

        },
        computed: {
            emofUserTokenSafer() {
                return this.$store.state.emofUserTokenSafer
            }
        },
        methods: {
            signOut() {
                this.$confirm(
                    '您确定要退出吗？',
                    '温馨提示', {
                        confirmButtonText: '确定',
                        type: 'warning',
                        closeOnClickModal: false,
                        closeOnPressEscape: false,
                    }
                ).then(() => {
                    this.$store.dispatch('LogOut').then(() => {
                        this.$router.replace('/login')
                        // location.reload() // 为了重新实例化vue-router对象 避免bug
                    })
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .header-container {
        background-color: #0a2240;
        box-shadow: 0px 6px 18px 1px rgba(10, 34, 64, 0.45);
        width: 100%;
        .header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 1200px;
            height: 100px;
            margin: 0 auto;
            .logo {
                margin: 20px 0;
                height: 50px;
            }
            .home {
                .service {
                    font-size: 14px;
                    color: #f5f5f5;
                    margin-right: 20px;
                }
                .to{
                    width: 99px;
                    height: 40px;
                    border-radius: 20px;
                    border: solid 1px #ffffff;
                    font-size: 16px;
                    line-height: 40px;
                    text-align: center;
                    color: #ffffff;
                    margin-right: 10px;
                }
                .to-emof{
                    width: 150px;
                    height: 41px;
                    background-image: linear-gradient(37deg, #ffb400 0%, #fc7802 100%),
                    linear-gradient(#ff9600, #ff9600);
                    background-blend-mode: normal, normal;
                    border-radius: 20px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    .title{
                        font-size: 14px;
                        font-weight: bold;
                        color: #ffffff;
                        text-align: center;
                    }
                    .tips{
                        font-size: 12px;
                        color: #021e3f;
                    }
                }
                .operate {
                    width: 99px;
                    height: 40px;
                    background-color: #ff9600;
                    border-radius: 20px;
                    font-size: 16px;
                    color: #ffffff;
                    line-height: 40px;
                    text-align: center;
                    cursor: pointer;
                    user-select: none;
                    margin-right: 10px;
                }
            }
        }
    }

</style>
