<template>
    <div class="warrper" :class="{black:isBlack}">
        Copyright 知诚会 <a :class="{black:isBlack}" target="_blank" href="http://beian.miit.gov.cn">  京ICP备17050284号-7</a>
        <img class="beian" src="http://www.beian.gov.cn/img/new/gongan.png"><a :class="{black:isBlack}" class="margin-right-xs" target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802037085">京公网安备11010802037085号</a>
        北京知诚社会组织众扶发展促进会  版权所有
    </div>
</template>
<script>
    export default {
        name: "FooterModular",
        data() {
            return {}
        },
        props: {
            isBlack: {
                type: Boolean,
                default: false
            }
        },
        created() {
        },
        methods: {}
    }
</script>

<style scoped lang="scss">
    .warrper {
        width: 100%;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-size: 14px;
        background-color: #0a2240;
        color: #ffffff;
        &.black {
            background-color: #2f2f2f;
            color: #f5f5f5;
        }
        .beian {
            width: 15px;
            height: 15px;
            margin: 0 5px;
        }

        a {
            font-size: 14px;
            color: #ffffff;
            margin: 0 10px;
            &.black {
                color: #f5f5f5;
            }
        }
    }
</style>